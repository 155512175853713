import { Box, Button, Chip, FormControl, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranscriptionDisplay } from '../../../context/TranscriptionDisplay.tsx';

const PresentationSourceMap = new Map<string, { label: string, placeholder: string, instruction: string, iconUrl: string}>([
    ["canva", {
        label: "Canva",
        iconUrl: "/canva.svg",
        instruction: "Enter your Canva Embed Link",
        placeholder: "Paste your Canva embed link here..."
    }],
    ["onedrive", {
        label: "OneDrive",
        iconUrl: "/onedrive.svg",
        instruction: "Enter your Onedrive slide embed code",
        placeholder: "Paste your OneDrive embed code here..."
    }],
    ["googleslides", {
        label: "Google Slides",
        iconUrl: "/googleslides.svg",
        instruction: "Enter the URL of your google slide",
        placeholder: "Paste your Google Slides url here..."
    }]
])

type Props = {
    onCancel: () => void;
    onDone: () => void;
}

export default function PresentationSourceModal({
    onCancel,
    onDone
}: Props) {
    const { presentationSource, setPresentationSource } = useTranscriptionDisplay();
    const originalPresentationSource = useRef<any>(null)
    const theme = useTheme();

    useEffect(() => {
        originalPresentationSource.current = presentationSource;
        // eslint-disable-next-line
    }, [])

    const validateUrl = useCallback((url: string): string => {
        const value = url;
        const urlSplitBySlash = value.split("/");
        let finalUrl = ''
        if (presentationSource.sourceApp === 'googleslides') {
            finalUrl = `https://docs.google.com/presentation/d/${urlSplitBySlash[urlSplitBySlash.indexOf('presentation')+2]}/embed`
        } else if (presentationSource.sourceApp === 'canva') {
            let srcUrl = ''
            if (value.includes(`src="`)) {
                srcUrl = value.split(`src="`)[1]
                srcUrl = srcUrl.split('"')[0]
                while (srcUrl.includes('&#x2F;')) {
                    srcUrl = srcUrl.replace('&#x2F;', '/')
                    console.log(srcUrl)
                }
                finalUrl = srcUrl;
            } else {
                if (!value.includes('?embed')) {
                    srcUrl = value + '?embed'
                } else {
                    srcUrl = value
                }
                finalUrl = srcUrl;
            }
        } else if (presentationSource.sourceApp === 'onedrive') {
            let srcUrl = ''
            if (value.includes('<iframe src=')) {
                srcUrl = value.split('"')[1]
            } else {
                srcUrl = value
            }
            while (srcUrl.includes('&amp;')) {
                srcUrl = srcUrl.replace('&amp;', '&')
            }
            finalUrl = srcUrl;
        }

        return finalUrl;
    }, [presentationSource.sourceApp])

    useEffect(() => {
        if (presentationSource.sourceApp && presentationSource.originalUrl) {
            const url = validateUrl(presentationSource.originalUrl);
            setPresentationSource((prev) => ({
                ...prev,
                sourceUrl: url
            }));
        }
    }, [presentationSource.sourceApp, presentationSource.originalUrl, setPresentationSource, validateUrl])

    const handlePresentationSourceAppChange = (sourceApp: string) => {
        setPresentationSource({
            ...presentationSource,
            sourceApp: sourceApp
        });
    }

    const handleSourceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = validateUrl(e.target.value);

        setPresentationSource({
            ...presentationSource,
            sourceUrl: url,
            originalUrl: e.target.value
        });
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleDoneClick();
        }
    }

    const handleCancelClick = () => {
        setPresentationSource(originalPresentationSource.current)
        onCancel();
    }

    const handleDoneClick = () => {
        onDone()
    }
  return (
    <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px',
        padding: '20px',
    }}>
        <Typography id="modal-modal-title" variant="h5">
            Select presentation source
        </Typography>
        <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            { [...PresentationSourceMap.entries()].map(([key, value]) => (
                <Chip 
                    key={key}
                    onClick={() => handlePresentationSourceAppChange(key)} 
                    label={<Stack direction="row" alignItems="center"><Box
                        component="img"
                        src={value.iconUrl}
                        alt={`${value.label} logo`}
                        sx={{
                            height: '20px',
                            marginRight: '5px'
                        }}
                    /> <Typography>{value.label}</Typography></Stack>} 
                    variant="outlined"
                    color={ presentationSource.sourceApp === key ? "primary" : "default"}
                />
            ))}
        </Stack>

        {
            presentationSource.sourceApp &&
            <FormControl fullWidth sx={{ mt: 3}}>
                <TextField 
                    value={presentationSource.originalUrl ?? ''} 
                    onChange={handleSourceUrlChange} 
                    onKeyPress={handleKeyPress}
                    label={PresentationSourceMap.get(presentationSource.sourceApp)?.instruction ?? "Enter URL"} 
                    placeholder={ PresentationSourceMap.get(presentationSource.sourceApp)?.placeholder} 
                    variant="outlined"
                />
            </FormControl>
        }

        <Stack sx={{ mt: 3}} direction="row" justifyContent="space-between">
            <Button onClick={handleCancelClick} variant="text" sx={{ color: 'grey'}} >Cancel</Button>
            <Button onClick={handleDoneClick} variant="contained" color="primary">Done</Button>
        </Stack>
    </Box>
  )
}