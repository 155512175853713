import { Box, Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import QRCode from 'react-qr-code';
import { useTranscriptionContext } from '../../../context/Transcription.tsx';

type Props = {
    onDone: () => void;
}

export default function ShareDetailsModal({
    onDone
}: Props) {
    const theme = useTheme();
    const { transcriptionId } = useTranscriptionContext()

    const qrCodeUrl = React.useMemo(() => {
        return `${process.env.REACT_APP_SUBSCRIBER_BASE_URL}/transcript/${transcriptionId}`
    }, [transcriptionId])

    const downloadSVG = () => {
        const svg = document.getElementById('qrCode');
        if (!svg) return;

        const serializer = new XMLSerializer();
        const svgBlob = new Blob([serializer.serializeToString(svg)], {
          type: 'image/svg+xml;charset=utf-8',
        });

        const url = URL.createObjectURL(svgBlob);

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `QR Code_event_${transcriptionId}.svg`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Revoke the URL to free up memory
        URL.revokeObjectURL(url);
    }

    const handleDoneClick = () => {
        onDone()
    }
    
  return (
    <Paper
    elevation={3}
    sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 300,
        width: '500px'
    }}
>
    <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px',
        padding: '20px',
    }}>
        <Typography id="modal-modal-title" variant="h5" textAlign="center">
            Access transcript on your device
        </Typography>

        <Box display="flex" marginY="20px" justifyContent="center" alignItems="center">
            {/* <img src={qrCode} alt="QR Code" height="300px" /> */}
            <QRCode id="qrCode" value={qrCodeUrl} size={250} bgColor={theme.palette.background.default} fgColor="#FFFFFF" />
        </Box>
        
        <Typography textAlign={'center'}>
            <Button variant="text" onClick={downloadSVG}>Download QR Code</Button>
        </Typography>

        <Typography textAlign={'center'} sx={{ mt: 2 }}>
            Or type the following URL in your browser:
        </Typography>

        <Typography textAlign={'center'} sx={{ mt: 2, fontWeight: 'bold' }}>
        { qrCodeUrl }
        </Typography>


        <Stack sx={{ mt: 3}} direction="row" justifyContent="flex-end">
            <Button onClick={handleDoneClick} variant="contained" color="primary">Done</Button>
        </Stack>
    </Box>
    </Paper>
  )
}