import React from 'react';
import { useTranscriptionDisplay } from '../../../context/TranscriptionDisplay.tsx';
import { Alert, Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SubscriberLanguageModal from './SubscriberLanguageModal.tsx';
import { trackEvent } from '../../../services/Analytics.ts';
import { useTheme } from '@mui/material/styles';
import { useTranscriptionContext } from '../../../context/Transcription.tsx';
import NoEventModal from '../../../components/NoEventModal.tsx';

type Props = {
  transcriptId: string
}

export default function SubscriberTranscript({
  transcriptId
}: Props) {
  const { transcriptionDisplayTheme: theme
  } = useTranscriptionDisplay();
  const { connectForTranscript, transcribedCaption, eventId, stopTranscription, setTranscriptId, setTargetLanguage, noEventScheduled } = useTranscriptionContext();
  const lastEventIdRef = React.useRef<string | null>(null);
  const muiTheme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [ transcripts, setTranscripts ] = React.useState<Record<string, string>>({})
  // const [ transcript, setTranscript ] = React.useState<string>('')
  const [ showLanguageModal, setShowLanguageModal ] = React.useState<boolean>(false);
  const [ showNoEventModal, setShowNoEventModal ] = React.useState<boolean>(false);
  // const [ showSaveTranscriptModal, setShowSaveTranscriptModal ] = React.useState<boolean>(false);
  const [ error ] = React.useState<string>('')
  const captionBox = React.useRef<HTMLDivElement>(null)
  const language = React.useMemo(() => {
    return searchParams.get('lang') ?? 'en-AU'
  }, [searchParams])
  
  React.useEffect(() => {
    setShowNoEventModal(noEventScheduled)
  }, [noEventScheduled])

  React.useEffect(() => {
    if (searchParams.has('lang')) {
      setSearchParams({ lang: language })
    }
  }, [searchParams, language, setSearchParams, setTargetLanguage])

  React.useEffect(() => {
    setTranscriptId(transcriptId)
  }, [transcriptId, setTranscriptId])

  React.useEffect(() => {
    if (lastEventIdRef.current === eventId) return
    stopTranscription()
    .then(() => {
      connectForTranscript(transcriptId)
        .then(() => {
          lastEventIdRef.current = eventId
        })
    })
  }, [eventId, stopTranscription, connectForTranscript, transcriptId])

  React.useEffect(() => {
    trackEvent({
      action_type: 'subscriber-language-select',
      event_id: transcriptId,
      metadata: {
        language: language,
      }
    
    }).then((res) => {
      console.log(res)
    }).catch(() => {
      console.error('Failed to track event')
    })

  }, [transcriptId, language])

  // React.useEffect(() => {
  //   setTranscript(transcripts[language] ?? '')
  // }, [transcripts, language])

  // React.useEffect(() => {
  //   connectForTranscript(transcriptId)
  //     .then(() => {
  //       console.log('here')
  //     })
  // }, [transcriptId, connectForTranscript])

  // React.useEffect(() => {
  //   const fetchWithInterval = () => {
  //     getSubscriberTranscript(transcriptId)
  //       .then((res) => {
  //         setTranscripts(res)
  //         timeout.current = setTimeout(fetchWithInterval, 4000)
  //       })
  //       .catch(err => {
  //         setError(err.message)
  //       })
  //   }

  //   fetchWithInterval();

  //   return () => {
  //     if (timeout.current) clearInterval(timeout.current)
  //   }
  // }, [transcriptId])

  React.useEffect(() => {
    captionBox.current!.scrollTop = captionBox.current!.scrollHeight;
  }, [transcribedCaption])

  // React.useEffect(() => {
  //   const seenSaveTranscriptModal = localStorage.getItem('seenSaveTranscriptModal')
  //   if (!seenSaveTranscriptModal) setShowSaveTranscriptModal(true)
  // }, [])

  const handleLanguageSelect = React.useCallback((lang: string) => {
    setSearchParams({ lang })
    setShowLanguageModal(false);
    setTargetLanguage(lang);
  }, [setSearchParams, setTargetLanguage])

  const handleLanguageModalClose = React.useCallback(() => {
    setShowLanguageModal(false)
  }, [])

  // const handleSaveTranscriptModalClose = React.useCallback(() => {
  // localStorage.setItem('seenSaveTranscriptModal', 'true')
  //   setShowSaveTranscriptModal(false)
  // }, [])

  const caption_sx = React.useMemo(() => ({
    //color: theme.fontColor,
    color: 'white',
    verticalAlign: '0em',
    // paddingLeft: 'calc(0.7em - 0.5ex)',
    // paddingRight: 'calc(0.7em - 0.5ex)',
    boxDecorationBreak: 'clone',
    WebkitBoxDecorationBreak: 'clone',
    wordSpacing: '0.075em',
    lineHeight: '1.5em',
    // fontFamily: (theme.fontFamily === "serif" ?
    // "Martel, Noto Serif SC, Noto Serif KR, serif" :
    // "Martel Sans, Noto Sans SC, Noto Sans KR, sans-serif"),
    fontFamily: "Martel Sans, Noto Sans SC, Noto Sans KR, sans-serif",
    fontSize: {
      xs: "1.5em",
      sm: "1.5em",
      md: "1.5em",
      lg: "2em",
      xl: "2em"
    },
    fontWeight: "600",
    marginTop: "auto",
    height: "fit-content",
    maxHeight: {
      xs: "80%",
      sm: "80%",
      md: "80%",
      lg: "70%",
      xl: "70%",
    },
    overflowY: "auto",
    position: 'absolute',
    //width: "calc(100% - 30vw)",
    width: {
      xs: "90vw",
      sm: "90vw",
      md: "85vw",
      lg: "80vw",
      xl: "75vw"
    },
    bottom: {
      xs: "20%",
      sm: "20%",
      md: "20%",
      lg: "30%",
      xl: "30%",
    },
    left: {
      xs: "5vw",
      sm: "5vw",
      md: "7.5vw",
      lg: "10vw",
      xl: "12.5vw"
    },
    right: {
      xs: "5vw",
      sm: "5vw",
      md: "7.5vw",
      lg: "10vw",
      xl: "12.5vw"
    },
    scrollBehavior: 'smooth',
    whiteSpace: 'pre-wrap',
    
  }), [])
  
  const transcript_box_sx = React.useMemo(() => ({
    backgroundColor: theme.backgroundColor,
    overflowY: 'scroll',
    position: 'relative',
    // maxWidth: '90vw',       /* Optional: Set a maximum width for the container */
    padding: "0vh 50vw 5vh 50vw",
    whitespace: "wrap",
    scrollBehavior: 'smooth',
    marginLeft: 'auto',
    marginRight: 'auto',
  }), [theme.backgroundColor])

  const languageModal = React.useMemo(() => 
    (<SubscriberLanguageModal
      onClose={handleLanguageModalClose}
      selectedLanguage={language}
      open={showLanguageModal}
      onLanguageSelect={handleLanguageSelect}
    />), 
  [handleLanguageSelect, language, showLanguageModal, handleLanguageModalClose])
  
  return (
    <Box
      sx={{
        backgroundColor: theme.backgroundColor,
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      { error && <Alert style={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: '90vw',
        zIndex: 1000
      }} severity="error">There was an error loading the transcript. Error: { error }</Alert> }
      { languageModal}
      
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)', // Add drop shadow
          position: 'relative', // Ensure the shadow is visible
          zIndex: 1, // Place the top bar above other elements
          backgroundColor: muiTheme.palette.background.default, // Use the default background color from the MUI theme
        }}
      >
        <a href="https://www.sunflowerai.io" target="_blank" rel="noopener noreferrer" style={{
          display: 'inline-block',
          height: '24px',
        }}>
          <Box
            component="img"
            src="/sunflower_light.svg"
            alt="Sunflower logo"
            sx={{
              height: '100%',
            }}
          />
        </a>
        {/* {!error &&
          <Chip
            icon={<TranslateIcon />}
            onClick={() => setShowLanguageModal(true)}
            label={`${languageOptions.get(language)}`}
            size="small"
          />
        } */}
      </Box>

      <Box className="hide__scrollbar transcript__box" sx={{
        ...transcript_box_sx,
        flexGrow: 1,
        overflow: 'auto',
      }}>
        <Typography ref={captionBox} className="hide__scrollbar caption__box" sx={{
          ...caption_sx,
        }}>{
          transcribedCaption
        }</Typography>
      </Box>

      <NoEventModal open={showNoEventModal} onClose={() => setShowNoEventModal(false)} /> 
      
      {/* { !error &&
        <Box sx={{ padding: '20px', marginLeft: 'auto' }}>
          <SaveTranscript />
        </Box>
      } */}

      {/* <SaveTranscriptDiscoveryModal
        open={showSaveTranscriptModal}
        onClose={handleSaveTranscriptModalClose}
      /> */}
    </Box>
  )
}