import React from 'react';
import { Box, Typography } from '@mui/material'
import { useTranscriptionContext } from '../../../context/Transcription.tsx'
import { useTranscriptionDisplay } from '../../../context/TranscriptionDisplay.tsx';
import { useTheme } from '@mui/material/styles';
import '../RunMeeting.css'
  
export default function RunMeetingFullTranscript() {
    const { transcriptionDisplayTheme: theme } = useTranscriptionDisplay();
    const muiTheme = useTheme();
    const { transcribedCaption } = useTranscriptionContext();
    const captionBox = React.useRef<HTMLDivElement>(null);

    //const { displayedText } = useTypewriterEffect(fullTranscription, 50);


    //const [displayedText, setDisplayedText] = React.useState('');
    //const [lastLength, setLastLength] = React.useState(0);

    React.useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (localStorage.getItem("is_connected") === "true" 
                && localStorage.getItem("local_sync") === "true" 
                && event.key === "transcripts") 
            {
                // I think I used the state "past" since the update of a ref
                // does not trigger rerender.
                // displayedText.current = localStorage.getItem("transcripts") || ""
                //setPast(localStorage.getItem("transcripts") || "")
            }
        }

        window.addEventListener("storage", handleStorageChange)
        return () => {
            window.removeEventListener("storage", handleStorageChange)
        }
    }, [])

    // React.useEffect(() => {
    //     if (fullTranscription.length > lastLength) {
    //       const interval = setInterval(() => {
    //         setDisplayedText(fullTranscription.slice(0, lastLength + 1));
    //         setLastLength(lastLength + 1);
    //       }, 50);
    
    //       return () => clearInterval(interval);
    //     }
    //   }, [fullTranscription, lastLength]);
    
    React.useEffect(() => {
        captionBox.current!.scrollTop = captionBox.current!.scrollHeight;
    }, [transcribedCaption]);

    const caption_sx = {
        color: theme.fontColor,
        verticalAlign: '0em',
        // paddingLeft: 'calc(0.7em - 0.5ex)',
        // paddingRight: 'calc(0.7em - 0.5ex)',
        boxDecorationBreak: 'clone',
        WebkitBoxDecorationBreak: 'clone',
        wordSpacing: '0.075em',
        lineHeight: '1.4em',
        fontFamily: (theme.fontFamily === "serif" ?
        "Martel, Noto Serif SC, Noto Serif KR, serif" :
        "Martel Sans, Noto Sans SC, Noto Sans KR, sans-serif"),
        fontSize: '4.25vh',
        fontWeight: "600",
        marginTop: "auto",
        height: "fit-content",
        maxHeight: '70%',
        overflowY: "auto",
        position: 'absolute',
        width: "calc(100% - 30vw)",
        bottom: "30%",
        left: "15vw",
        right: "15vw",
        scrollBehavior: 'smooth',
        whiteSpace: 'pre-wrap'
    
    }
    
    const transcript_box_sx = React.useMemo(() => ({
        backgroundColor: muiTheme.palette.background.paper, // Use the default background color
        overflowY: 'scroll',
        height: '100vh',
        maxHeight: '100vh',
        position: 'relative',
        maxWidth: '100vw',
        padding: "0vh 15vw 5vh 15vw",
        whitespace: "wrap"
    }), [muiTheme.palette.background.paper])

    const displayedText = React.useMemo(() => {
        return transcribedCaption;
    }, [transcribedCaption]);

    return (
        <Box className="hide__scrollbar" sx={transcript_box_sx}>
            <Typography ref={captionBox} className="hide__scrollbar" sx={{
                ...caption_sx,
            }}>
                {/* when not using local storage transcript, show displayedText.current
                otherwise use the state past straightaway (no animation though)
                localStorage.getItem("local_sync") === "false" ? displayedText.current : past */}
                {displayedText}
            </Typography>
             {/* <Typography sx={{
                ...caption_sx,
                height: 'fit-content',
                opacity: 0.5,
                top: "73%",
                overflowY: "hidden",
                bottom: "unset"
            }}>{currentSegment}</Typography> */}
        </Box>
    )
}