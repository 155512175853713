import { format } from "date-fns";

type ValidEvent = "subscriber-language-select" |
"load-audience-page" |
"save-transcript"


interface EventTrack {
    action_type: ValidEvent;
    event_id: string;
    user_id?: number;
    metadata : Record<string, any>;
}

export async function trackEvent(event: EventTrack) {
  const date = new Date(); // Your JavaScript date object

  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
  
  // Format the UTC date as an ISO string
  const utcIsoString = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  const body: EventTrack & {action_time: string} = {
    ...event,
    action_time: utcIsoString
  }
  const res = await fetch(`${process.env.REACT_APP_SUNFLOWER_BACKEND_URL}/analytics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  const result = await res.text()
  return result;
}