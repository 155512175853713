import React from 'react'
import RunMeetingHeaderSection from './RunMeetingHeaderSection.tsx'
import { Button, ClickAwayListener, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, useTheme } from '@mui/material'
import ShareIconOutlined from '@mui/icons-material/ShareOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranscriptionDisplay } from '../../../context/TranscriptionDisplay.tsx';
import { useTranscriptionContext } from '../../../context/Transcription.tsx';
import { MoreVert } from '@mui/icons-material';

export default function HeaderMoreSection() {
    const theme = useTheme()
    const { toggleShowHeader, setShowCustomPhrasesModal, setShowPresentationSourceModal, setShowShareDetailsModal } = useTranscriptionDisplay()
    const { downloadTranscription, resetTranscript } = useTranscriptionContext()
    const [ moreElement, setMoreElement ] = React.useState<null | HTMLElement>(null);
    const open = Boolean(moreElement);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setMoreElement(event.currentTarget);
    };

    const handleShowHeaderClick = () => {
        toggleShowHeader()
        setMoreElement(null);
    }

    const handleShowShareClick = () => {
        setShowShareDetailsModal(true);
        setMoreElement(null);
    }

    const handleCustomPhrasesClick = () => {
        setShowCustomPhrasesModal(true);
        setMoreElement(null);
    }

    const handleClearTranscriptClick = () => {
        resetTranscript();
        setMoreElement(null);
    }

  return (
    <RunMeetingHeaderSection label="More">
        <>
            <Button
                id="hide-header-btn"
                sx={{
                    color: 'white',
                    textTransform: 'none',
                }}
                startIcon={<VisibilityOffOutlinedIcon />}
                onClick={handleShowHeaderClick}
            >
                Hide Header
            </Button>
            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
               aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
                <Menu
                    open={open}
                    anchorEl={moreElement}
                >
                    <ClickAwayListener onClickAway={() => setMoreElement(null)}>
                        <MenuList
                            dense
                        >
                            {/* <MenuItem
                                onClick={handleShowHeaderClick}
                            >
                                <ListItemIcon>
                                    <VisibilityOffOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Hide header</ListItemText>
                            </MenuItem> */}
                            <MenuItem
                                onClick={handleShowShareClick}
                             >
                                <ListItemIcon>
                                    <ShareIconOutlined />
                                </ListItemIcon>
                                <ListItemText>Show share details</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={handleClearTranscriptClick}
                             >
                                <ListItemIcon>
                                    <BackspaceOutlinedIcon color='error' />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        color: theme.palette.error.main
                                    }}
                                >Clear Transcript</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => setShowPresentationSourceModal(true)}>
                                <ListItemText>Change presentation source</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleCustomPhrasesClick}>
                                <ListItemText>Update custom phrases</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={downloadTranscription}>
                                <ListItemText>Download transcript</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Menu>
        </>
    </RunMeetingHeaderSection>
  )
}