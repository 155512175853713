import React from 'react'
import RunMeetingHeaderSection from './RunMeetingHeaderSection.tsx'
import { List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, Tooltip, Divider } from '@mui/material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranscriptionContext } from '../../../context/Transcription.tsx';
import { RefreshOutlined } from '@mui/icons-material';

export default function HeaderAudioInputSection() {
    const { setInputDevice, inputDevice, isTranscribing } = useTranscriptionContext()
    const [ audioInputElement, setAudioInputElement ] = React.useState<null | HTMLElement>(null);
    const audioInputOpen = Boolean(audioInputElement);
    const [ inputDevices, setInputDevices ] = React.useState<MediaDeviceInfo[]>([])

    const doOrganiseDevices = React.useCallback(async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
        setInputDevices(audioInputDevices)
        if (!inputDevice) {
            const selectedDevice = audioInputDevices.find(inputDevice => inputDevice.deviceId === "default");
            if (selectedDevice) {
                setInputDevice(selectedDevice)
            }
        }
    }, [setInputDevice, inputDevice])

    React.useEffect(() => {
        doOrganiseDevices();
    }, [doOrganiseDevices]);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        if (!isTranscribing) {
            setAudioInputElement(event.currentTarget);
        }
    };
  
    const handleMenuItemClick = (
        option: string
    ) => {
        if (!isTranscribing) {
            setInputDevice(inputDevices.find(inputDevice => inputDevice.deviceId === option) ?? null);
            setAudioInputElement(null);
        }
    };
  
    const handleClose = () => {
        setAudioInputElement(null);
    };

    const disabledStyle = isTranscribing ? { cursor: 'not-allowed' } : {};

    return (
        <RunMeetingHeaderSection label="Audio Input">
            <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
                <List sx={{
                    padding: 0,
                    maxWidth: '150px',
                    ...disabledStyle
                }}>
                    <ListItemButton
                        sx={{ 
                            padding: 0.2,
                            paddingLeft: 1.5,
                            paddingRight: 1,
                            borderRadius: "40px",
                        }}
                        aria-expanded={audioInputOpen ? 'true' : undefined}
                        onClick={handleClickListItem}
                        disabled={isTranscribing}
                    >
                        <ListItemText primary={<Typography noWrap>{inputDevice?.label}</Typography>} />
                        <ListItemIcon sx={{ minWidth: 'unset'}}><ExpandMoreOutlinedIcon /></ListItemIcon>
                    </ListItemButton>
                </List>
            </Tooltip>
            <Menu
                anchorEl={audioInputElement}
                open={audioInputOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {inputDevices.map((device) => (
                    <MenuItem
                        key={device.deviceId}
                        selected={device.deviceId === inputDevice?.deviceId}
                        onClick={() => handleMenuItemClick(device.deviceId)}
                    >
                        {device.label}
                    </MenuItem>
                ))}
                 <Divider />
                <MenuItem onClick={doOrganiseDevices}>
                    <ListItemIcon>
                        <RefreshOutlined />
                    </ListItemIcon>
                    <ListItemText>Refresh Audio Devices</ListItemText>
                </MenuItem>
            </Menu>
        </RunMeetingHeaderSection>
    )
}