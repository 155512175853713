import React from 'react';
import { useTranscriptionContext } from './Transcription.tsx';

interface UsageControlContextState {
    secondsLeft: number;
    enforceControl: boolean;
    limitReached: boolean;
    
}

interface UsageControlProviderProps extends React.PropsWithChildren {}


export const UsageControlContext = React.createContext<UsageControlContextState | undefined>(undefined);

export const UsageControlProvider: React.FC<UsageControlProviderProps> = ({ children }) => {

    const { transcriptionStatus, stopTranscription } = useTranscriptionContext()
    const minuteCounterInterval = React.useRef<NodeJS.Timeout | null>(null);
    const enforceControl = process.env.REACT_APP_APPLICATION_ENVIRONMENT === 'demo' ? true : false;
    
    const requiresReset = React.useMemo(() => {
        const localTimeStartTime = localStorage.getItem('sf_ts');
        if (!localTimeStartTime) return true

        if (isNaN(parseInt(localTimeStartTime))) {
            return true
        }

        const timeAsNumber = parseInt(localTimeStartTime)

        if (Date.now().valueOf() - timeAsNumber > 1000 * 60 * 60 * 24) return true
        else return false
    }, [])

    const initialSecondsUsed = React.useMemo(() => {
        if (requiresReset) return 0;

        const localSecondsUsed = localStorage.getItem('sf_su');
        if (!localSecondsUsed) return 0

        if (isNaN(parseInt(localSecondsUsed))) {
            return 0
        }

        return parseInt(localSecondsUsed)
    }, [requiresReset])


    const [ secondsUsed, setSecondsUsed ] = React.useState<number>(initialSecondsUsed);

    React.useEffect(() => {
        if (requiresReset && enforceControl) {
            localStorage.setItem('sf_ts', Date.now().valueOf().toString());
        }
    }, [requiresReset, enforceControl])

    React.useEffect(() => {
        if (enforceControl) {
            localStorage.setItem('sf_su', secondsUsed.toString());
            if (secondsUsed >= 30 * 60) {
                stopTranscription();
            }
        }
    }, [secondsUsed, enforceControl, stopTranscription])

    React.useEffect(() => {
        if (transcriptionStatus === 'started' && enforceControl) {
            minuteCounterInterval.current = setInterval(() => {
                setSecondsUsed(prev => prev + 1);
            }, 1000)
        }

        return () => {
            if (minuteCounterInterval.current) {
                clearInterval(minuteCounterInterval.current)
            }
        }
    }, [transcriptionStatus, enforceControl])

    return (
        <UsageControlContext.Provider value={{
            secondsLeft: Math.max((30 * 60) - secondsUsed, 0),
            enforceControl,
            limitReached: secondsUsed >= (30 * 60) && enforceControl
        }}>
            {children}
        </UsageControlContext.Provider>
    );
};

export const useUsageControlContext = () => {
    const context = React.useContext(UsageControlContext)
    if (context === undefined) throw new Error('useUsageControlContext must be used within a UsageControlProvider')
    
    return context
}