import React from 'react'
import { format } from 'date-fns';
import { isValidTranscriptionId } from '../utils/TranscriptioinId.ts';
import { createSubscriberTranscript } from '../services/SubscriberTranscript.ts';
import { TranscriptionType } from '../containers/RunMeeting/components/HeaderTranscriptionSection';
import { RoomEvent } from '../models/Event.ts';
import { getEventsByRoomId } from '../services/Events.ts';

export type LanguageSettingsConfig = {
    source: string,
    target: string
}

interface TranscriptionContextType {
    startTranscription: (initialPrompt?: string) => void;
    stopTranscription: () => Promise<void>;
    pauseTranscription: () => void;
    unpauseTranscription: () => void;
    downloadTranscription: () => void;
    setSourceLanguage: (source: string) => void;
    setTargetLanguage: (target: string) => void;
    setInputDevice: (inputDevice: MediaDeviceInfo | null) => void;
    setTranscriptId: (transcriptionId: string) => void;
    resetTranscript: () => void;
    connectForTranscript: (roomId: string) => Promise<void>;
    customPhrases: string[];
    setCustomPhrases: (phrases: string[]) => void;
    inputDevice: MediaDeviceInfo | null;
    transcriptionStatus: "starting" | "unstarted" | "paused" | "started" | "stopped";
    transcribedCaption: string;
    fullTranscription: string;
    currentSegment: string;
    languageSettings: LanguageSettingsConfig,
    defaultAudienceLanguages: string[];
    transcriptionId: string;
    isTranslating: boolean;
    getRecognisedText: () => string;
    transcriptionType: TranscriptionType;
    setTranscriptionType: React.Dispatch<React.SetStateAction<TranscriptionType>>;
    isTranscribing: boolean;
    isEventsLoaded: boolean;
    noEventScheduled: boolean;
    // noMatchDetected: boolean;
    eventId: string | null;
    currentEvent: RoomEvent | null;
}

const TranscriptionContext = React.createContext<TranscriptionContextType | undefined>(undefined);

interface TranscriptionProviderProps extends React.PropsWithChildren {
    transcriptionType: TranscriptionType;
}

export const TranscriptionProvider: React.FC<TranscriptionProviderProps> = ({
    children,
    transcriptionType: externalTranscriptionType
}: TranscriptionProviderProps) => {
    // const audioChunks = React.useRef<Blob[]>([]);
    const transcriptionSocket = React.useRef<WebSocket | null>(null);
    const audioProcessor = React.useRef<AudioWorkletNode | null>(null);
    const audioContext = React.useRef<AudioContext | null>(null);
    const audioSource = React.useRef<MediaStreamAudioSourceNode | null>(null);
    const [ transcriptionId, setTranscriptionId ] = React.useState<string>("");
    const [ transcriptionStatus, setTranscriptionStatus ] = React.useState<"starting" | "unstarted" | "paused" | "started" | "stopped">("unstarted")
    const [ languageSettings, setLanguageSettings ] = React.useState<LanguageSettingsConfig>({
        source: "en-AU",
        target: "en-AU"
    })
    const [ customPhrases, setCustomPhrases ] = React.useState<string[]>([]);
    const [ inputDevice, setInputDevice ] = React.useState<MediaDeviceInfo | null>(null);
    const [ events, setEvents ] = React.useState<RoomEvent[] | null>(null);
     const [ eventId, setEventId ] = React.useState<string | null>(null);
     const [ event, setEvent ] = React.useState<RoomEvent | null>(null);
    // const recognizer = React.useRef<SpeechRecognizer | TranslationRecognizer | null>(null);
    const [ recognisingTexts, setRecognisingTexts ] = React.useState<Record<string, string>>({})
    const [ recognisedTexts, setRecognisedTexts ] = React.useState<Record<string, string>>({})
    const defaultAudienceLanguages = React.useRef(["en-AU","zh-CN","ar-SA","vi-VN","it-IT","el-GR","hi-IN","es-ES","pa-IN","fil-PH", "id-ID", "ko-KR", "ja-JP"])
    // const audienceAvailableLanguages = React.useRef(defaultAudienceLanguages.current)
    const [transcriptionType, setTranscriptionType] = React.useState<TranscriptionType>(externalTranscriptionType);
    // const [noMatchDetected, setNoMatchDetected] = React.useState(false);
    // const noMatchCountRef = React.useRef(0);
    const eventSwitcherIntervalRef = React.useRef<NodeJS.Timeout | null>(null);

    const updateIntervalRef = React.useRef<number>(3000);


    React.useEffect(() => {
        if (transcriptionId) {
            getEventsByRoomId(transcriptionId)
                .then(events => {
                    setEvents(events);
                })
        }
    }, [transcriptionId])


    React.useEffect(() => {
        if (events) {
            const currentTime = new Date();
            const currentEvent = events.find((event) => {
                return new Date(event.sched_start) <= currentTime && new Date(event.sched_end) >= currentTime;
            });
            if (currentEvent) {
                setEventId(currentEvent.uuid);
                setEvent(currentEvent);
                const newLangSettings = {
                    source: '',
                    target: ''
                }
                newLangSettings.source = currentEvent.source_language.split(',').includes('en-AU')  ? 'en-AU' : currentEvent.source_language.split(',')[0];
                newLangSettings.target = currentEvent.target_languages.includes('en-AU')  ? 'en-AU' : currentEvent.target_languages[0];
                setLanguageSettings(newLangSettings);
            } else {
                setEventId(null);
                setEvent(null);
                setLanguageSettings({
                    source: 'en-AU',
                    target: 'en-AU'
                })
            }
        } else {
            setEventId(null);
            setEvent(null);
            setLanguageSettings({
                source: 'en-AU',
                target: 'en-AU'
            })
        }

        if (!eventSwitcherIntervalRef.current && events) {
            eventSwitcherIntervalRef.current = setInterval(() => {
                if (events.length > 0) {
                    const currentTime = new Date();
                    const currentEvent = events.find((event) => {
                        return new Date(event.sched_start) <= currentTime && new Date(event.sched_end) >= currentTime;
                    });
                    if (currentEvent && currentEvent.uuid !== eventId) {
                        setEventId(currentEvent.uuid);
                        setEvent(currentEvent);
                        const newLangSettings = {
                            source: '',
                            target: ''
                        }
                        newLangSettings.source = currentEvent.source_language.split(',').includes('en-AU')  ? 'en-AU' : currentEvent.source_language.split(',')[0];
                        newLangSettings.target = currentEvent.target_languages.includes('en-AU')  ? 'en-AU' : currentEvent.target_languages[0];
                        setLanguageSettings(newLangSettings);
                    }
                }
            }, 1000 * 10);
        }


        return () => {
            if (eventSwitcherIntervalRef.current) {
                clearInterval(eventSwitcherIntervalRef.current)
                eventSwitcherIntervalRef.current = null;
            }
        }
    }, [events, eventId])

    // React.useEffect(() => {
    //     setTranscriptionType(externalTranscriptionType);
    //     updateIntervalRef.current = externalTranscriptionType === 'caption-only' ? 3000 : 4000;
    // }, [externalTranscriptionType]);

    const TEXT_REPLACEMENTS = React.useMemo(() => [
        // { from: /\b((ki|con)nect series)\b/gi, to: "Connect Series" },
        // { from: /\b(((say|see|saint) (three|3)|sister) church|sis research)\b/gi, to: "C3 Church" },
        // { from: /\b((plenty|planet) shakers)\b/gi, to: "Planetshakers" },
        { from: /\b(fish burners)\b/gi, to: "Fishburners" },
        { from: /\b((spa|smog|spike|spy|spark) festival)\b/gi, to: 'Spark Festival' },
        { from: /\b(((stone|stern)( and)?|stunning) (chalk|chuck))\b/gi, to: 'Stone & Chalk' },
        // { from: /\b(l(yn|i)ng? (zhu|cher)|(link|lynch|rang)er)\b/gi, to: 'Lingzhi' },
        { from: /\b(saa?ss?)\b/gi, to: 'SaaS' },
        { from: /\b(city (to |de)(surf|serve))\b/gi, to: 'City2Surf' },
        // { from: /\b(fine run)\b/gi, to: 'Fun Run' },
        { from: /\b(uni ?church)\b/gi, to: 'Unichurch' },
        // { from: /\b(ma?c?kk?ie?)\b/gi, to: 'Mckie' },
        { from: /\b(s2 ?s summit)\b/gi, to: 'S2S Summit' },
        { from: /\b(life captioning)\b/gi, to: 'live captioning' },
        { from: /\b(life captions)\b/gi, to: 'live captions' },
        { from: /\b(life caption)\b/gi, to: 'live caption' },
        { from: /\b(life translations)\b/gi, to: 'live translations' },
        { from: /\b(life translation)\b/gi, to: 'live translation' },
        // { from: /\b([cz]hu ?h(a[io]?|ong)])\b/gi, to: 'Chuhao' },
        { from: /\b(south by south ?west)\b/gi, to: 'SXSW' },
        { from: /\b(south by)\b/gi, to: 'SXSW' },
        { from: /\b(cockle bay one)\b/gi, to: 'Cockle Bay 1' },
        { from: /\b(cockle bay two)\b/gi, to: 'Cockle Bay 2' },
    ], []);

    const preloadedPhrases = React.useMemo(() => {
        const automaticPhrases = TEXT_REPLACEMENTS.map(replacement => replacement.to);
        const manualPhrases = [
            // Add any additional phrases here
            "SXSW",
            "Chau Chak",
            "Cockle Bay 1",
            "Cockle Bay 2",
            "Collaborative Theatre",
            "Darling Harbour Room",
            "Darling Harbour Theatre",
            "Discovery Stage", "INVNT",
            "Parkside Ballroom 1",
            "Pyrmont Theatre",
            "C2.2",
            "C2.3",
            "C2.5", "C2.6", "Short Talks",
            "C3.2",
            "C3.3",
            "C3.6", "Podcast Stage",
            "E3.5",
        ];
        return [...new Set([...automaticPhrases, ...manualPhrases])];
    }, [TEXT_REPLACEMENTS]);

    const applyReplacements = React.useMemo(() => {
        return (text: string) => {
            // Apply apostrophe replacement
            text = text.replace(/'/g, '\u2019');
            
            // Apply other replacements
            return TEXT_REPLACEMENTS.reduce((result, replacement) => {
                return result.replace(replacement.from, replacement.to);
            }, text);
        };
    }, [TEXT_REPLACEMENTS]);

    const lastUpdateTimeRef = React.useRef<number>(0);

    // const createRecognizer = React.useCallback(async () => {
    //     if (recognizer.current) {
    //         await new Promise((resolve) => {
    //             recognizer.current!.stopContinuousRecognitionAsync(() => {
    //                 resolve(true);
    //             })
    //         });
    //         await new Promise((resolve) => {
    //             recognizer.current!.close(() => {
    //                 resolve(true);
    //             });
    //         })

    //         recognizer.current = null;
    //     }

    //     const stream = await navigator.mediaDevices.getUserMedia({
    //         audio: { deviceId: inputDevice?.deviceId}
    //     });
        
    //     const audioConfig = AudioConfig.fromStreamInput(stream);
    //     const translationConfig = SpeechTranslationConfig.fromSubscription(process.env.REACT_APP_COG_SERVICE_KEY!, process.env.REACT_APP_COG_SERVICE_LOCATION!);
    //     if (process.env.REACT_APP_COG_SERVICE_ENDPOINT_ID) {
    //         translationConfig.endpointId = process.env.REACT_APP_COG_SERVICE_ENDPOINT_ID;
    //     }
    //     // translationConfig.setProperty('SpeechServiceResponse_StablePartialResultThreshold', "15");
    //     translationConfig.setProperty('SpeechServiceResponse_TranslationRequestStablePartialResult', "true");
    //     translationConfig.speechRecognitionLanguage = languageSettings.source;
    //     translationConfig.addTargetLanguage(languageSettings.target);
    //     audienceAvailableLanguages.current.forEach((lang) => {
    //         translationConfig.addTargetLanguage(lang);
    //     });

    //     recognizer.current = new TranslationRecognizer(translationConfig, audioConfig);
    //     if (customPhrases.length > 0 || preloadedPhrases.length > 0) {
    //         const phraselist = PhraseListGrammar.fromRecognizer(recognizer.current);
    //         phraselist.addPhrases([...customPhrases, ...preloadedPhrases]);
    //     }

    //     const conn = Connection.fromRecognizer(recognizer.current);
    //     conn.setMessageProperty("speech.context", "phraseDetection", {
    //         "INTERACTIVE": {
    //             "segmentation": {
    //                 "mode": "custom",
    //                 "segmentationSilenceTimeoutMs": 1000
    //             }
    //         },
    //         mode: "Interactive"
    //     });

    //     recognizer.current.recognizing = (sender, event) => {
    //         const currentTime = Date.now();
    //         const isTranslating = languageSettings.source !== languageSettings.target;
    //         const updateInterval = isTranslating ? updateIntervalRef.current : 0;
            
    //         // Reset noMatchCount when speech is recognized
    //         noMatchCountRef.current = 0;
    //         setNoMatchDetected(false);
            
    //         if (currentTime - lastUpdateTimeRef.current >= updateInterval) {
    //             setRecognisingTexts({...recognizingTextsRef.current});
    //             lastUpdateTimeRef.current = currentTime;
    //         }

    //         // Always update the ref
    //         if (!translationConfig.targetLanguages.includes(recognizer.current!.speechRecognitionLanguage)){
    //             recognizingTextsRef.current[recognizer.current!.speechRecognitionLanguage] = applyReplacements(event.result.text);
    //         }
    //         translationConfig.targetLanguages.forEach((lang) => {
    //             const trans = event.result.translations.get(lang === 'zh-CN' ? 'zh-Hans' : lang.split('-')[0]);
    //             recognizingTextsRef.current[lang] = applyReplacements(trans || event.result.text);
    //         });
    //     }

    //     recognizer.current.recognized = (sender, event) => {
    //         // Clear recognizing texts when a new segment begins
    //         recognizingTextsRef.current = {};
    //         setRecognisingTexts({});

    //         if (event.result.reason === ResultReason.TranslatedSpeech) {
    //             // Reset noMatchCount when speech is recognized
    //             noMatchCountRef.current = 0;
    //             setNoMatchDetected(false);
    //             setRecognisedTexts((prev) => {
    //                 const newRecognisedTexts = {
    //                     ...prev,
    //                 };
    //                 if (!translationConfig.targetLanguages.includes(recognizer.current!.speechRecognitionLanguage)){
    //                     newRecognisedTexts[recognizer.current!.speechRecognitionLanguage] = (newRecognisedTexts[recognizer.current!.speechRecognitionLanguage] || '') + applyReplacements(event.result.text) + "\n\n";
    //                 }
    //                 translationConfig.targetLanguages.forEach((lang) => {
    //                     const trans = event.result.translations.get(lang === 'zh-CN' ? 'zh-Hans' : lang.split('-')[0]);
    //                     newRecognisedTexts[lang] = (newRecognisedTexts[lang] || '') + applyReplacements(trans || event.result.text) + "\n\n";
    //                 });
    //                 return newRecognisedTexts;
    //             });
    //         } else if (event.result.reason === ResultReason.NoMatch) {
    //             console.log('NOMATCH: Speech could not be recognized.');
    //             if (transcriptionType === 'caption-only') {
    //                 noMatchCountRef.current += 1;
    //                 if (noMatchCountRef.current >= 4) {
    //                     setNoMatchDetected(true);
    //                 }
    //             }
    //         }
    //     }
    //     // } else {
    //     //     const speechConfig = SpeechConfig.fromSubscription(process.env.REACT_APP_COG_SERVICE_KEY!, process.env.REACT_APP_COG_SERVICE_LOCATION!);
    //     //     speechConfig.setProperty('speechSegmentationSilenceTimeoutMs', "1000");
    //     //     recognizer.current = new SpeechRecognizer(speechConfig, audioConfig);

    //     //     recognizer.current.recognizing = (sender, event) => {
    //     //         setRecognisingText(event.result.text.replace(/'/g, '’'));
    //     //     };

    //     //     recognizer.current.recognized = (sender, event) => {
    //     //         setRecognisingText('');
    //     //         if (event.result.reason === ResultReason.RecognizedSpeech) {
    //     //           setRecognisedText((recognisedText) => {
    //     //             const curlyText = event.result.text.replace(/'/g, '’');
    //     //             return `${recognisedText}${curlyText} `;
    //     //           });
    //     //         } else if (event.result.reason === ResultReason.NoMatch) {
    //     //           console.log('NOMATCH: Speech could not be recognized.');
    //     //         }
    //     //     };
    //     // }

    //     recognizer.current.canceled = (sender, event) => {
    //         if (event.reason === CancellationReason.Error) {
    //           console.log(`CANCELED: ErrorCode=${event.errorCode}`);
    //           console.log(`CANCELED: ErrorDetails=${event.errorDetails}`);
    //           console.log('CANCELED: Did you set the speech resource key and region values?');
    //         }

    //         if (recognizer.current) recognizer.current.stopContinuousRecognitionAsync();
    //       };
      
    //       recognizer.current.sessionStopped = (sender, event) => {
    //         if (recognizer.current) recognizer.current.stopContinuousRecognitionAsync();
    //       };

    // }, [inputDevice, languageSettings, customPhrases, applyReplacements, preloadedPhrases, transcriptionType])

    const disconnectFromWebSocket = React.useCallback(() => {
        return new Promise((resolve) => {
            if (transcriptionSocket.current && transcriptionSocket.current.readyState === WebSocket.OPEN) {
                transcriptionSocket.current.onclose = () => {
                    transcriptionSocket.current = null;
                    resolve(true)
                }
                transcriptionSocket.current.close();
            } else if (transcriptionSocket.current?.readyState === WebSocket.CONNECTING) {
                transcriptionSocket.current.onopen = () => {
                    if (transcriptionSocket.current) {
                        transcriptionSocket.current.onclose = () => {
                            transcriptionSocket.current = null;
                            resolve(true)
                        }
                    }
                }
            } else {
                resolve(true)
            }
        })
    }, [])

    const stopMicrophone = React.useCallback(() => {
        if (audioProcessor.current) {
            audioProcessor.current.disconnect();
            audioProcessor.current = null;
        }

        if (audioSource.current) {
            audioSource.current.disconnect();
            audioSource.current = null;
        }

        if (audioContext.current) {
            audioContext.current.close();
            audioContext.current = null;
        }
    }, [])

    const startMicrophone = React.useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: inputDevice?.deviceId}
            });
            audioContext.current = new AudioContext();
            await audioContext.current.audioWorklet.addModule('/new-audio-processor.js');
            audioSource.current = audioContext.current.createMediaStreamSource(stream);
            audioProcessor.current = new AudioWorkletNode(audioContext.current, 'new-audio-processor');
            audioProcessor.current.port.onmessage = (event) => {
                const float32Array = event.data;
                const int16Array = new Int16Array(float32Array.length);
                for (let i = 0; i < float32Array.length; i++) {
                    int16Array[i] = Math.max(-1, Math.min(1, float32Array[i])) * 0x7FFF;
                }
            
                if (transcriptionSocket.current && transcriptionSocket.current.readyState === WebSocket.OPEN) {
                    transcriptionSocket.current.send(int16Array.buffer);
                }
            };
            audioSource.current.connect(audioProcessor.current);
        } catch (error) {
            console.log((error as Error).message);
            console.error('Error accessing microphone:', error);
            alert('Error accessing microphone. Please check your settings and try again.');
            stopMicrophone();          
        }
    }, [inputDevice?.deviceId, stopMicrophone])

    const isTranslating = React.useMemo(() => {
        return languageSettings.source !== languageSettings.target;
    }, [languageSettings]);    

    const connectToWebSocket = React.useCallback(async ({
        roomId,
        eventId,
        isSpeaker = true
    }: {
        roomId: string;
        eventId: string;
        isSpeaker: boolean;
    }) => {
        transcriptionSocket.current = new WebSocket(process.env.REACT_APP_SOCKET_URL! + `/ws/${roomId}`);

        transcriptionSocket.current.onopen = async () => {
            // Send initial configuration
            const config = {
                event_id: eventId,
                source_language: languageSettings.source,
                target_languages: event?.target_languages || [],
                phrase_list: [...customPhrases, ...preloadedPhrases, ...event?.phrase_list || []],
            };

            if (transcriptionSocket.current && transcriptionSocket.current.readyState === WebSocket.OPEN) {
                transcriptionSocket.current.send(JSON.stringify(config));
                if (isSpeaker) await startMicrophone()
                setTranscriptionStatus('started');
            }
        }

        transcriptionSocket.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'translating') {
                const now = Date.now();
                if ((now - lastUpdateTimeRef.current >= updateIntervalRef.current) || !isTranslating) {
                    setRecognisingTexts(prev => {
                        const newTexts = {...prev};
                        Object.keys(data.translations).forEach((lang) => {
                            newTexts[lang] = applyReplacements(data.translations[lang]);
                        })
                        return newTexts;
                    });
                    lastUpdateTimeRef.current = now;
                } else {
                    const direct = data.translations[languageSettings.source];
                    setRecognisingTexts(prev => {
                        const newTexts = {...prev};
                        if (direct) {
                            newTexts[languageSettings.source] = applyReplacements(direct);
                        }
                        return newTexts;
                    });  
                }

            } else if (data.type === 'translated') {
                setRecognisingTexts(prev => {
                    const newTexts = {...prev};
                    Object.keys(data.translations).forEach((lang) => {
                        newTexts[lang] = "";
                    })
                    return newTexts;
                });
                setRecognisedTexts(prev => {
                    const newTexts = {...prev};
                    Object.keys(data.translations).forEach((lang) => {
                        if (!newTexts[lang]) {
                            newTexts[lang] = '';
                        }
                        newTexts[lang] += applyReplacements(data.translations[lang]);
                        newTexts[lang] += "\n\n";
                        if (newTexts[lang].length > 6000) {
                            newTexts[lang] = newTexts[lang].slice(-6000);
                        }
                    });
                    return newTexts
                });
            }
        }

        transcriptionSocket.current.onclose = () => {
            stopMicrophone();
        }

        transcriptionSocket.current.onerror = function(error) {
            console.error('WebSocket error:', error);
            disconnectFromWebSocket();
            stopMicrophone();
            if (window.confirm('A dip in internet caused your connection to drop. Would you like to reconnect?')) {
                window.location.reload();
            }
        };
    }, [languageSettings, startMicrophone, stopMicrophone, disconnectFromWebSocket, applyReplacements, event?.target_languages, customPhrases, preloadedPhrases, event?.phrase_list, isTranslating])


    // React.useEffect(() => {
    //     if (location.pathname.includes('event')) {
    //         if (transcriptionId) {
    //             createRecognizer()
    //                 .then(() => {
    //                     if (transcriptionStatus === 'started') {
    //                         startTranscription();
    //                     }
    //                 });
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [createRecognizer, transcriptionId, location])

    // React.useEffect(() => {
    //     const doUpdate = async () => {
    //         const request = {}
    //         defaultAudienceLanguages.current.forEach((lang) => {
    //             request[lang] = (recognisedTexts[lang] || '') + (recognisingTexts[lang] || '');
    //         })
    //         await updateSubscriberTranscript(request, transcriptionId);
    //     }
    //     if (transcriptionId && transcriptionStatus !== 'stopped') {
    //         doUpdate();
    //     }
    // }, [transcriptionId, transcriptionStatus, recognisedTexts, recognisingTexts])

    const addTranscriptId = React.useCallback((transcriptionId: string) => {
        const isValid = isValidTranscriptionId(transcriptionId);
        if (!isValid) {
            throw new Error('Invalid transcription ID');
        }

        createSubscriberTranscript(transcriptionId, defaultAudienceLanguages.current)
        .then(() => {
            setTranscriptionId(transcriptionId);
        });
        setTranscriptionId(transcriptionId);
    }, [])

    const connectForTranscript = React.useCallback(async (roomId: string) => {
        if (!transcriptionSocket.current && eventId) {
            await connectToWebSocket({
                roomId: roomId,
                eventId: eventId,
                isSpeaker: false
            })
        }
    }, [connectToWebSocket, eventId])

    const startTranscription = async () => {
        setTranscriptionStatus('starting')
        if (eventId) {
            await connectToWebSocket({
                roomId: transcriptionId,
                eventId: eventId,
                isSpeaker: true
            })
        }
    }
        
    const pauseTranscription = () => {
        stopMicrophone();
        setTranscriptionStatus('paused');
    }
    
    const unpauseTranscription = () => {
        if (transcriptionSocket.current) {
            startMicrophone()
                .then(() => {
                    setTranscriptionStatus('started');
                })
        } else {
            stopMicrophone();
            startTranscription();
        }
    }

    const stopTranscription = React.useCallback(async () => {
        setTranscriptionStatus('stopped');
        await disconnectFromWebSocket()
        stopMicrophone();
    }, [disconnectFromWebSocket, stopMicrophone])

    const downloadTranscription = () => {
        const now = new Date();
        const sourceLanguage = languageSettings.source;
        const transcriptionText = recognisedTexts[sourceLanguage] || '';
        const blob = new Blob([transcriptionText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `Transcription Export_${sourceLanguage}_${format(now, 'dd MMM yyyy HH.mm.ss')}.txt`;
        link.href = url;
        link.click();
    }

    const setSourceLanguage = (source: string) => {
        stopMicrophone();
        disconnectFromWebSocket();
        setLanguageSettings((prev) => ({
            ...prev,
            source: source,
        }))
    }

    const setTargetLanguage = (target: string) => {
        stopMicrophone();
        disconnectFromWebSocket();
        setLanguageSettings((prev) => ({
            ...prev,
            target: target,
        }))
    }
    
    const getTranscribedCurrentSegment = () => {
        const recognising = recognisingTexts[languageSettings.target.split("-")[0]] || '';
        return recognising;
    }
    
    const getRecognisedText = () => {
        const recognised = recognisedTexts[languageSettings.target.split("-")[0]] || '';
        return recognised;
    }

    const getTranscribedCaption = () => {
        // if (noMatchDetected && transcriptionType === 'caption-only') {
        //     return "Live captions provided by Sunflower AI";
        // }
        const recognised = getRecognisedText();
        const recognising = getTranscribedCurrentSegment();
        return recognised + "" + recognising;
    }

    const resetTranscript = () => {
        setRecognisedTexts(prev => {
            const newRecognisedTexts = {...prev};
            Object.keys(newRecognisedTexts).forEach((key) => {
                newRecognisedTexts[key] = '';
            });
            return newRecognisedTexts;
        });
        setRecognisingTexts(prev => {
            const newRecognisingTexts = {...prev};
            Object.keys(newRecognisingTexts).forEach((key) => {
                newRecognisingTexts[key] = '';
            });
            return newRecognisingTexts;
        })
    }

    const isTranscribing = React.useMemo(() => {
        return transcriptionStatus === 'started' || transcriptionStatus === 'starting';
    }, [transcriptionStatus]);

    return (
        <TranscriptionContext.Provider
            value={{
                startTranscription,
                stopTranscription,
                downloadTranscription,
                pauseTranscription,
                unpauseTranscription,
                setSourceLanguage,
                setTargetLanguage,
                connectForTranscript,
                transcribedCaption: getTranscribedCaption(),
                currentSegment: getTranscribedCurrentSegment(),
                transcriptionStatus,
                languageSettings,
                inputDevice,
                fullTranscription: getRecognisedText(),
                setInputDevice,
                transcriptionId,
                setTranscriptId: addTranscriptId,
                resetTranscript,
                defaultAudienceLanguages: defaultAudienceLanguages.current,
                customPhrases,
                setCustomPhrases,
                isTranslating,
                getRecognisedText,
                transcriptionType,
                setTranscriptionType,
                isTranscribing,
                eventId,
                currentEvent: event,
                isEventsLoaded: !!events,
                noEventScheduled: !eventId && !!events,
            }}
        >
            { children }
        </TranscriptionContext.Provider>
    )
}

export const useTranscriptionContext = () => {
    const context = React.useContext(TranscriptionContext)
    if (context === undefined) throw new Error('useTranscriptionContext must be used within a TranscriptionProvider')
    return context
}