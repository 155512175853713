import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { generateTranscriptionId } from '../../utils/TranscriptioinId.ts';

export default function EmptyMeeting() {
    const navigate = useNavigate();
    useEffect(() => {
        const id = generateTranscriptionId();
        navigate(`/event/${id}`);
    }, [navigate])
  return (<></>)
}