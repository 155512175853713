import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import RunMeetingHeader from './components/RunMeetingHeader.tsx'
import './RunMeeting.css'
import { useTranscriptionDisplay } from '../../context/TranscriptionDisplay.tsx'
import { useTranscriptionContext } from '../../context/Transcription.tsx'
import RunMeetingCaptionOnly from './components/RunMeetingCaptionOnly.tsx'
import RunMeetingFullTranscript from './components/RunMeetingFullTranscript.tsx'
import ShareDetailsModal from './components/ShareDetailsModal.tsx'
import { useParams } from 'react-router-dom'
import UsageLimitTimer from './components/UsageLimits/UsageLimitTimer.tsx'
import { useUsageControlContext } from '../../context/UsageControl.tsx'
import UsageLimitReachedModal from './components/UsageLimits/UsageLimitReachedModal.tsx'
import UsageLimitEnforcedModal from './components/UsageLimits/UsageLimitEnforcedModal.tsx'
import CustomPhrasesModal from './components/CustomPhrasesModal.tsx'
import NoEventModal from '../../components/NoEventModal.tsx'

export default function RunMeeting() {
    const { 
      transcriptionDisplayTheme,
      transcriptionType,
      showShareDetails,
      setShowShareDetailsModal,
      setShowCustomPhrasesModal,
      showCustomPhrasesModal,
      showHeader
    } = useTranscriptionDisplay()
    const { setTranscriptId, noEventScheduled } = useTranscriptionContext();
    const { enforceControl, limitReached } = useUsageControlContext();
    const [ showLimitModal, setShowLimitModal ] = React.useState<boolean>(false);
    const [ showLimitEnforcedModal, setShowLimitEnforcedModal ] = React.useState<boolean>(enforceControl);
    const [ showNoEventModal, setShowNoEventModal ] = React.useState<boolean>(false);
    const { id } = useParams<{ id: string }>();
    const transcriptId = React.useMemo(() => {
      return id?.toUpperCase();
    }, [id])
    const wakeLock = React.useRef<WakeLockSentinel | null>(null);


  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: 'default'}
       });

        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        
      }
    }

    checkMicrophonePermission();
  }, [])
      
  useEffect(() => {
    setShowNoEventModal(noEventScheduled)
  }, [noEventScheduled])


    useEffect(() => {
      if (enforceControl && limitReached && !showLimitEnforcedModal) {
        setShowLimitModal(true);
      }
    }, [enforceControl, limitReached, showLimitEnforcedModal])

    useEffect(() => {
        if (transcriptId) {
          setTranscriptId(transcriptId);
        }
    }, [transcriptId, setTranscriptId]);

    const requestWakeLock = async () => {
      try {
        wakeLock.current = await navigator.wakeLock.request('screen');

        wakeLock.current.addEventListener('release', () => {
          console.log('Screen Wake Lock released:', wakeLock?.current?.released);
        });



        console.log('Screen Wake Lock acquired:', wakeLock.current.released);
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };

    const handleVisibilityChange = React.useCallback(async () => {
      console.log('Visibility changed to:', document.visibilityState)
      if (wakeLock.current !== null && document.visibilityState === 'visible') {
        await requestWakeLock();
      }
    }, [])

    useEffect(() => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      requestWakeLock();
  
      // Cleanup on component unmount
      return () => {
        if (wakeLock.current) {
          wakeLock.current.release().then(() => {
            console.log('Screen Wake Lock released on cleanup');
          });
        }
      };
    }, [handleVisibilityChange]);

  return (
    <Box sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        backgroundColor: transcriptionType === "full-screen" ? 'inherit' : transcriptionDisplayTheme.backgroundColor,
    }}>
        <RunMeetingHeader />
        {
          showShareDetails && <ShareDetailsModal onDone={() => setShowShareDetailsModal(false)} />
        }
        {
          showCustomPhrasesModal && <CustomPhrasesModal onDone={() => setShowCustomPhrasesModal(false)} onCancel={() => setShowCustomPhrasesModal(false)} />
        }
        {

        }
        {
            transcriptionType === "full-screen" ?
              <RunMeetingFullTranscript />
            :
              <RunMeetingCaptionOnly />
        }
        { showLimitEnforcedModal &&
          <UsageLimitEnforcedModal onClose={() => setShowLimitEnforcedModal(false)} open={showLimitEnforcedModal} />
        }
        {
          showLimitModal &&
          <UsageLimitReachedModal onClose={() => setShowLimitModal(false)} open={showLimitModal}  />
        }
        { enforceControl &&
          <Box sx={{
            position: 'absolute',
            bottom: "10px",
            left: "10px"
          }}>
            <UsageLimitTimer />
          </Box>
        }
        {transcriptionType === "full-screen" && (
          <a href="https://www.sunflowerai.io" target="_blank" rel="noopener noreferrer" style={{
            position: 'absolute',
            top: '38px',
            left: '35px',
            height: '30px',
            zIndex: 101,
            opacity: showHeader ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}>
            <Box
              component="img"
              src="/sunflower_light.svg"
              alt="Sunflower logo"
              sx={{
                height: '100%',
              }}
            />
          </a>
        )}

        <NoEventModal open={showNoEventModal} onClose={() => setShowNoEventModal(false)} /> 
    </Box>
  )
}