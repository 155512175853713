import React from 'react'
import { useParams } from 'react-router-dom';
import SubscriberCodeEmpty from './components/SubscriberCodeEmpty.tsx';
import SubscriberTranscript from './components/SubscriberTranscript.tsx';
import './SubscriberPage.css';
import { trackEvent } from '../../services/Analytics.ts';

export default function SubscriberPage() {
    const { transcriptId } = useParams<{ transcriptId?: string }>();
    const transformedId = React.useMemo(() => {
      return transcriptId?.toUpperCase();
    }, [transcriptId])

    React.useEffect(() => {
      if (transformedId) {
        trackEvent({
          action_type: 'load-audience-page',
          event_id: transformedId,
          metadata: { }
        
        }).then((res) => {
        }).catch(() => {
          console.error('Failed to track event')
        })
      }
    }, [transformedId])

  return (

    <>
      { transformedId ?
        <SubscriberTranscript transcriptId={transformedId}  />  
        :
        <SubscriberCodeEmpty />
    }
    </>
  )
}