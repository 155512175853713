export async function createSubscriberTranscript(id: string, languages: string[]) {
  const res = await fetch(`${process.env.REACT_APP_SUNFLOWER_BACKEND_URL}/create_transcript/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      languages: languages
    })
  });

  const result = await res.text()
  return result;
}

export async function updateSubscriberTranscript(transcripts: Record<string, string>, id: string) {
  const res = await fetch(`${process.env.REACT_APP_SUNFLOWER_BACKEND_URL}/update_transcript/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transcripts)
  });

  const result = await res.text()
  return result;
}


export async function getSubscriberTranscript(id: string): Promise<Record<string, string>>{
  const res = await fetch(`${process.env.REACT_APP_SUNFLOWER_BACKEND_URL}/get_transcript/${id}`);
  if (res.status !== 200) {
    const errorMessage = await res.text();
    throw new Error(errorMessage);
  }
  const result = await res.json()
  return result;
}